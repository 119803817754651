<template>
  <div v-if="data">
    <div class="company-info-card">
      <div class="company-logo">
        <el-image
          style="width: 140px; height: 140px"
          :src="data.logo_url||require('assets/mainLogo.png')"
          fit="cover"
        />
      </div>
      <div class="company-info">
        <div class="company-info-left">
          <div class="title">
              {{data.company_name||'公司名称'}} 
              <a @click="changMotal" class="star-rate" v-if="data.has_comment_status">
                <i class="el-icon-edit icon"></i>
                <div style="margin-left: 5px">评价企业</div>
              </a>
          </div>
          <div class="sub-info">
            <span>{{data.city||"公司所在城市"}}</span><span class="line">|</span>
            <span>{{data.company_scale||"公司人数"}}</span><span v-if="data.industry" class="line">|</span>
            <span>{{data.industry}}</span>
          </div>
        </div>
        <div class="company-info-right">
          <div class="job-text">在招职位</div>
          <div class="num">{{data.published_job_num}}</div>
        </div>
      </div>
      <el-dialog
      title="评价企业"
      :visible.sync="visible"
      width="40%"
      >
      <div class="rate-wrapper">
        <el-rate
          v-model="score"
          :colors="rateColors"
          show-text
          :texts="texts">
        </el-rate>
        <div class="tags">
          <div v-for="(item) in tags" :key="item" class="tag" :class="tagActive(item)&&'active'" @click="selectTag(item)">
            {{item}}
          </div>          
        </div>
        <div class="rate-input">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8}"
            placeholder="描述对企业的评价"
            v-model="inputValue">
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changMotal">取 消</el-button>
        <el-button type="primary" @click="hundleOk">确 定</el-button>
      </span>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name:"companyInfo",
  props:["data"],
  data:()=>({
    visible:false,
    score:4,
    rateColors: ['#99A9BF', '#F7BA2A', '#FF9900'],
    texts:['很差',"差","一般","好","很好"],
    tags:["工作环境好","氛围活跃","交通便利","执行力强","同事很棒","福利好"],
    selectTags:[],
    inputValue:""
  }),
  methods:{
    changMotal(){
      this.visible=!this.visible
    },
    hundleOk: async function (){
      if(!this.inputValue||!this.score){
        this.$message.error('请填写评价');
        return
      }        
      let res = await this.$axiosRequest({
        method:"POST", 
        name: "postStarRete",
        token:true,
        ids:[this.userId,this.data.company_id],
        data:{
        score:this.score,
        content:{
          tags:this.selectTags,
          evaluation:this.inputValue
        }}
      })

      if (res.status === 200||res.status === 201) {
        this.$message({
          message:"提交成功",
          type:"success"
        });
        this.changMotal()
      }else{
        this.$message.error('请重试');
      }
    },
    selectTag(value){
      let selectTags = [...this.selectTags]
      if(selectTags.find(item=>item===value)){
        selectTags.splice(selectTags.indexOf(value),1);
      }else{
        selectTags.push(value)
      }
      this.selectTags = selectTags
    },
    tagActive(value){
      return this.selectTags.find(item=>item===value)
    }
  },
  computed:{
    ...mapState({
      userId:state=>state.user_id,
    })
  },
  watch:{},
}
</script>
<style lang="scss">
@import "constants/style/public";
.company-info-card{
  width: 100%;
  padding: 20px;
  background-color: $defaultBg;
  box-shadow: 0px 0px 18px $shadowColor;
  display: flex;
  border-radius:0 0 $borderRadius $borderRadius;
  .company-logo{
    overflow: hidden;
    flex:0 0 auto;
    width: 140px;
    height: 140px;
    border-radius: $borderRadius;
    border: 1px solid $lineColor;
  }
  .company-info{
    margin-left: 55px;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    font-size: $large;
    color: $cardsubTextColor;
    text-align: left;
    .company-info-left{
      justify-content: space-evenly;
      .title{
        font-size: $extraExtraLarge;
        color: $titleColor;
        display: flex;
        align-items: flex-end;
      }
      .star-rate{
        margin-left: 20px;
        font-size: 14px;
        display: flex;
        color: var(--primaryColor);
        cursor: pointer;
      }
    }
    .company-info-right{
      justify-content: center;
      text-align: right;
      .job-text{
        font-size: $medium;
      }
      .num{
        margin-top: 13px;
        font-size: $extraExtraLarge;
        color: $moreColor;

      }
    }
    .company-info-right,.company-info-left{
      display: flex;
      flex-direction: column;
    }
  }
  .rate-wrapper{
    .tags{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .tag{
        border-radius: 999px;
        color: var(--primaryColor);
        border: 1px solid var(--primaryColor);
        padding: 2px 6px;
        margin-right: 10px;
        margin-bottom: 10px;
        &.active{
          background-color: var(--primaryColor);
          color: #fff;
        }
      }
    }
    .rate-input{
      margin-top: 10px;
    }
  }
}

</style>

