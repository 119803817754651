<template>
  <div class="maps">
    <div class="map-head-text">{{ address }}</div>
    <div
      class="maps-wrapper"
      :style="'width:' + width + ';height:' + height + ';'"
      id="map-container"
    ></div>
  </div>
</template>
<script>
export default {
  name: "Map",
  props: ["width", "height", "address"],
  data: () => ({
    map: null,
    centerPoint: null,
  }),
  mounted() {
    this.initMap(this.address);
  },
  methods: {
    initMap(address = "衢州市") {
      if (window.BMap) {
        let BMap = window.BMap;
        if (!this.map) {
          this.map = new BMap.Map("map-container");
          let map = this.map;
          let point = new BMap.Point(116.404, 39.915);
          this.map.centerAndZoom(point, 15);

          let myGeo = new BMap.Geocoder();
          myGeo.getPoint(
            address,
            (point) => {
              // console.log(point);
              if (point) {
                this.centerPoint = point;
                map.centerAndZoom(point, 16);
                map.addOverlay(new BMap.Marker(point));
              } else {
                alert("您选择地址没有解析到结果!");
              }
            },
            "衢州市"
          );
          map.enableScrollWheelZoom(true);
        }
      }
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";
.maps {
  .map-head-text {
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 50px;
    color: $titleColor;
    background: $defaultBg;
    border: 1px solid $searchBg;
    border-radius: $borderRadius $borderRadius 0px 0px;
  }
  .maps-wrapper {
    border-radius: 0px 0px $borderRadius $borderRadius ;
  }
}
</style>