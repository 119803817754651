<template>
  <div class="job-list hasPagination" v-loading="loading">
    <div class="job-list-wrapper" :class="cardVersion ? 'newWrapper' : ''">
      <div v-for="(l, i) in data" :key="i" class="item" link="#">
        <JobCard :isExam="isExam" :version="cardVersion" :data="l"
          :link="toJobDetail + l.job_id + (cfId ? `/?cf_id=${cfId}` : '')"
          :toCompany="toCompanyDetail + l.company_id + (cfId ? `/?cf_id=${cfId}` : '')" :sixIndustry="sixIndustry" />
      </div>
    </div>
    <div class="pagination" v-if="nums && pagination">
      <el-pagination background layout="prev, pager, next" :current-page="page" :total="total" :page-size="nums"
        :hide-on-single-page="!pagination || !total || total < nums" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import JobCard from "components/jobCard";
import { toJobDetail, toCompanyDetail } from "constants/routerLink"
export default {
  name: "JobListWrapper",
  props: {
    nums: {
      type: Number,
      default: 12
    },
    pagination: {
      type: Boolean,
      default: false
    },
    filterData: {
      type: Object,
      default() {
        return {}
      }
    },
    ajaxApi: {
      type: String,
      default: 'getHotJobs'
    },
    cfId: String,
    ids: Array,
    cardVersion: [String, Number, null, undefined, Boolean],
    isExam: {
      type: Boolean,
      default: false
    },
    sixIndustry: Boolean,
  },
  data() {
    return {
      page: 1,
      total: null,
      data: null,
      loading: false,
    }
  },
  created() {
    console.log(this.cardVersion);

    this.getData();
  },
  computed: {
    toJobDetail: () => toJobDetail,
    toCompanyDetail: () => toCompanyDetail
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    getData() {
      let data = { ...this.filterData }
      let ids = []
      let params = {
        career_fair_id: this.cfId || null,
        limit: this.nums,
        offset: (this.page - 1) * this.nums,
      };
      if (this.ids && this.ids.length) {
        ids = this.ids
      }
      if (Object.values(this.filterData).length) {
        delete data.curIndex
        params = Object.assign(params, data);
      }
      this.loading = true;
      // console.log(params);
      this.$axiosRequest({
        name: this.ajaxApi,
        params: params,
        ids: ids
      })
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200) {
            this.data = res.data.results;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    filterData(val, prev) {
      if (val !== prev) {
        this.page = 1;
        this.getData();
      }
    },
  },
  components: {
    JobCard,
  },
};
</script>
<style lang="scss">
@import "constants/style/public";

.el-loading-spinner .path {
  stroke: $primaryColor;
}

.job-list {
  .job-list-wrapper {
    min-height: 200px;

    display: flex;
    flex-wrap: wrap;

    .item {
      margin-right: 36px;
      margin-bottom: 36px;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    &.newWrapper {
      .item {
        margin-right: 15px;
        margin-bottom: 15px;

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .pagination {
    text-align: center;
  }
}
</style>