<template>
  <div class="introduction">
    <TextHeader v-if="!!title" :title="title" />
    <!-- 富文本 -->
    <div class="content" v-if="!!content" v-html="htmlText"/>
    <!-- 地图 -->
    <div class="city-map">
      <Maps width="100%" height="360px" v-if="!!addressMap" :address="city"/>
    </div>
    <!-- 二维码 -->
    <div class="qr" v-if="!!qrUrl">
      <el-image
      style="width: 170px; height: 170px"
      :src="qrUrl"
      fit="cover"/>
    </div>
    <!-- 手机号 邮箱 -->
    <div class="contact" v-if="!!email||!!phone">
      <div class="item" v-if="!!phone">联系电话：<span>{{phone}}</span></div>
      <div class="item" v-if="!!email">联系邮箱：<span>{{email}}</span></div>
    </div>
  </div>
</template>
<script>
import TextHeader from 'components/textHeader'
import Maps from 'components/maps'
import { htmlUnEscape } from 'constants/utils'
export default {
  name:"Introduction",
  props:["title","content","addressMap","qrUrl","email","phone"],
  data:()=>({
    
  }),
  methods:{},
  computed:{
    city(){
      let reg = /[-]+/g
      let str = this.addressMap
      if(reg.test(str)){
        str = str.replace(reg,"")
      }
      return str
    },
    htmlText(){
      return htmlUnEscape(this.content)
    }

  },
  watch:{},
  components:{
    TextHeader,
    Maps
  }
}
</script>
<style lang="scss">
@import "constants/style/public";
.introduction{
  margin: 10px 0;
  .content{
    // margin-top:20px;
  }
  .contact{
    // display: flex;
    margin-left: 14px;
    margin-bottom: 20px;
    .item{
      width: 50%;
      margin-bottom: 10px;
    }
  }
}
</style>