<template>
  <div>
    <!-- 新版 -->
    <a target="_blank" v-if="version === 'new' || version === 2.0" :href="link" class="job-card-new toLink">
      <div class="text-wrapper-new">
        <header class="card-head">
          <div class="job-title_price">
            <div class="job-title">
              <div class="job-title-content ellipsis">{{ data.job_title }}</div>
              <div style="margin-left:5px;font-size:12px;" v-if="false">{{ 1234 }}</div>
            </div>
            <div class="job-price">{{ salary }}</div>
          </div>
          <div class="company-name">
            <a target="_blank" :href="toCompany">{{ data.company_name }}</a>
          </div>
          <div class="job-tags">
            <el-tag class="job-tag" type="info" size="mini">{{ data.experience }}经验</el-tag>
            <el-tag class="job-tag" type="info" size="mini">{{ data.education }}学历</el-tag>
            <el-tag class="job-tag city-tag ellipsis" type="info" size="mini">{{ data.city }}</el-tag>
            <el-tag class="job-tag" type="info" size="mini">{{ data.employment_type }}</el-tag>
          </div>
        </header>
        <footer v-if="sixIndustry && sixIndustryTag" class="card-foot flex">
          <div class="tags">
            <div class="tag" v-for="(item, index) in sixIndustryTag" :key="index">
              {{ item }}
            </div>
          </div>
          <span>更新时间：{{ updateTime }}</span>
        </footer>
        <footer v-else class="card-foot">
          <span>更新时间：{{ updateTime }}</span>
        </footer>
      </div>
    </a>
    <!-- 旧版 -->
    <a target="_blank" v-else :href="link" class="job-card toLink">
      <div class="text-wrapper">
        <div class="title">
          <div class="left-text ellipsis">{{ data.job_title }}</div>
          <div class="salary">{{ salary }}</div>
        </div>
        <div class="company-name ellipsis">{{ data.company_name }}</div>
        <div class="jobs-bottom">
          <div class="other">
            {{ city }}<span class="line">|</span>{{ data.education }}<span class="line">|</span>{{ data.experience }}
          </div>
          <div v-if="data.updated_at">更新于 {{ updateTime }}</div>
        </div>

      </div>
    </a>
    <!-- <div v-else class="job-card">
      <div class="text-wrapper">
        <div class="title">
          <div class="left-text ellipsis">{{data.job_title}}</div>
          <div class="salary">{{salary}}</div>
        </div>
        <div class="company-name">{{data.company_name}}</div>
        <div class="other">
          {{city}}<span class="line">|</span>{{data.education}}<span class="line">|</span>{{data.experience}}
        </div>
      </div>
    </div> -->
  </div>

</template>
<script>
import { jobSalaryType } from "constants/publicData"

export default {
  name: "JobCard",
  props: ['data', 'link', 'version', 'toCompany', 'sixIndustry'],
  data: () => ({

  }),
  methods: {},
  computed: {
    updateTime() {
      if (this.data) {
        return this.$dayjs(this.data.updated_at).format('YYYY/MM/DD')
      } else return ""
    },
    salary() {
      let salary_min;
      let salary_max;
      let min_key;
      let max_key;
      let up = false
      if (Number(this.data.salary_min) > 999) {
        salary_min = this.data.salary_min / 1000;
        min_key = true;
      }
      if (Number(this.data.salary_max) > 999) {
        if (Number(this.data.salary_max) == 99999) {
          up = true
        }
        salary_max = this.data.salary_max / 1000;
        max_key = true;
      }
      if (salary_min && salary_min > 0) {
        if (salary_max && salary_max > 0) {

          if (min_key && max_key) {
            // console.log(salary_min,salary_max);   
            if (up) {
              return (salary_min + "K/月 以上 ")
            } else {
              return (
                salary_min + "K-" +
                salary_max + "K" +
                // jobSalaryType[this.data.salary_unit]||
                "月"
              );
            }
          } else {
            return (
              salary_min +
              "-" +
              salary_max +
              // jobSalaryType[this.data.salary_unit]||
              "月"
            );
          }
        } else {
          if (min_key) {
            return salary_min + "K" +
              // jobSalaryType[this.data.salary_unit];
              "月"
          }
          return salary_min +
            jobSalaryType[this.data.salary_unit] +
            "月";
        }
      } else {
        if (salary_max && salary_max > 0) {
          if (max_key) {
            return salary_max + "K" +
              // jobSalaryType[this.data.salary_unit]
              "月";
          }
          return salary_max +
            // jobSalaryType[this.data.salary_unit]
            "月";
        }
      }
      return "面议";
    },
    city() {
      if (this.data && this.data.city) {
        let cityStr = this.data.city
        if (cityStr) {
          let arr = cityStr.split("-")
          return arr[arr.length - 1]
        }
      }
      return ''
    },
    sixIndustryTag() {
      if (this.data && this.data.quzhou_key_industry) {
        return this.data.quzhou_key_industry.slice(0,2)
      } else return []
    }
  },
  watch: {},

}
</script>
<style lang="scss">
@import 'constants/style/public';

.job-card {
  display: block;
  width: 273px;
  height: 99px;
  padding: 10px;
  background-color: var(--cardColor);
  border: 1px solid var(--borderLineColor, #F4F4F4);

  &.toLink {
    cursor: pointer;
  }

  .text-wrapper {
    text-align: left;
    font-size: var(--little);
    color: var(--cardsubTextColor);

    .title {
      font-size: var(--medium, 14px);
      color: var(--titleColor);
      font-weight: var(--weight-medium);
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      .left-text {
        width: 60%;
      }

      .salary {
        color: var(--moreColor, #666);
      }
    }

    .company-name {
      margin-bottom: 8px;
    }

    .jobs-bottom {
      display: flex;
      justify-content: space-between;
    }
  }

}

.job-card-new {
  text-align: left;
  display: block;
  color: var(--titleColor);

  &.toLink {
    cursor: pointer;
  }

  .text-wrapper-new {
    width: 390px;
    height: 134px;
    padding: 18px 20px 0;
    background-color: var(--cardColor);
    border: 1px solid var(--borderLineColor, #F4F4F4);
    font-size: 12px;

    &:hover {
      >.card-head>.job-title_price {
        color: var(--moreColor, #666);
      }
    }

    .card-head {
      border-bottom: 1px solid #F7F8FA;

      .job-title_price,
      .company-name,
      .job-tag {
        margin-bottom: 8px;
      }

      .job-title_price {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        color: var(--titleColor);

        .job-title {
          display: flex;
          align-items: center;

          .job-title-content {
            max-width: 240px;
          }
        }

        .job-price {
          color: var(--moreColor, #666);
        }
      }

      .company-name {
        >a {
          color: var(--titleColor);

          &:hover {
            color: var(--moreColor, #666);
            text-decoration: underline;
          }
        }
      }

      .job-tags {
        display: flex;
        align-items: center;
        width: 100%;

        .job-tag {
          flex: none;
          margin-right: 5px;
        }

        .city-tag {
          max-width: 160px;
        }
      }
    }

    .card-foot {
      text-align: right;
      color: var(--promptColor);
      padding: 5px 0;

      &.flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tags {
          display: flex;

          .tag {
            padding: 1px 4px;
            background-color: $primaryColor;
            color: #fff;
            margin-right: 2px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
</style>