<template>
  <div class="text-header">
    <div class="title">{{title}}</div>
    <a target="_blank" class="more" v-if="link" :href="link" :key="link" >查看更多>></a>
  </div>
</template>
<script>
export default {
  name:"",
  props:{
    title:[String,Number],
    link:[String],
  },
  data:()=>({
    
  }),
  methods:{},
  computed:{},
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.text-header{
  margin: 12px 0;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  .title{
    padding: 0 18px;
    border-left: 6px solid $moreColor;
    font-size: $extraLarge;
    font-weight: $weight-medium;
  }
  .more{
    color:$moreColor;
    &:hover{
      text-decoration: underline;
    }
  }
}
</style>