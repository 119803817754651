<template>
  <div>
    <div class="tab-nav-card">
      <div class="tab-nav-item">
        <div :class="'filter-job-cate ' + (curIndex === i ? 'active' : '')" v-for="(l, i) in list" :key="i"
          @click="toggleCate(i)" v-text="l.label" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tabNav",
  props: {
    curIndex: {
      type: Number,
      default: 0,
    },
    list: Array,
  },
  data: () => ({}),
  methods: {
    toggleCate(i) {
      this.$emit("change", i);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";

.tab-nav-card {
  background-color: $defaultBg;
  font-size: $medium;
  margin-top: 15px;
  padding: 15px 10px;
  border: 1px solid $borderLineColor;
}

.tab-nav-item {
  display: flex;

  .filter-job-cate {
    position: relative;
    cursor: pointer;
    margin: 0 13px;
    padding: 7px 23px;

    &.active {
      &::after {
        width: 100%;
        height: 2px;
        content: "";
        position: relative;
        top: 12px;
        background-color: $moreColor;
        display: block;
      }
    }
  }
}
</style>