<template>
  <div class="company-tetail page-container">
    <CompanyInfoCard v-if="data" :data="data"/>
    <DetailTabNav :curIndex="curIndex" :list="list" @change="toggle"/>
    <div class="company-introduction" v-if="curIndex===0&&data">
      <div class="left">
        <Introduction title="公司简介" :content="data.company_profile" v-if="data"/>

        <Introduction title="联系方式" :phone="data.phone_number" :email="data.email" v-if="data"/>

        <Introduction title="公司地点" :addressMap="city" v-if="data" style="margin-top:20px"/>
      </div>
      <div class="right">
        <Introduction title="进入小程序" v-if="data" :qrUrl="data.qr_code_url"/>
      </div>
    </div>
    <div v-if="curIndex===1">
      <JobListWrapper style="margin-top:26px" pagination :ajaxApi="'getCompanyJobs'" :nums="24" :cfId="cfId" :ids="[data.global_company_id]"/>
    </div>
  </div>
</template>
<script>
import CompanyInfoCard from "components/company/detail/companyDetailInfo"
import DetailTabNav from "components/detailTabNav"
import Introduction from "components/introduction"
import JobListWrapper from "components/jobs/jobListWrapper";
import {companyDetailNavList} from "constants/publicData"
import {mapState} from "vuex"
export default {
  name:"CompanyDetail",
  data:()=>({
    data:null,
    curIndex:0,
  }),
  computed:{
    ...mapState({
      user_id:state=>state.user_id,
      isToken:state=>!!state.token
    }),
    list:()=>companyDetailNavList,
    city(){
      if(this.data.city){
        if(this.data.address){
          return this.data.city+this.data.address
        }else{
          return this.data.city
        }
      }else{
        if(this.data.address){
          return this.data.address
        }else{
          return ""
        }
      }
    },
    cfId(){
      return this.$route.query?.cf_id?this.$route.query.cf_id:undefined
    }
  },
  created(){
    this.getData()
  },
  methods:{
    toggle(i){
      this.curIndex = i
    },
    getData() {
      let key = this.isToken?'getCompanyDetailIsToken':"getCompanyDetail"
      this.$axiosRequest({
        name: key,
        ids:[this.$route.params.id,this.user_id],
      })
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch:{},
  components:{
    CompanyInfoCard,
    DetailTabNav,
    Introduction,
    JobListWrapper
  }

}
</script>
<style lang="scss">
@import "constants/style/public";
.company-tetail{
  text-align: left;
  padding-bottom: 60px;
  .company-introduction{
    margin-top: 25px;
    display: flex;
    .left{
      padding-right: 45px;
      width: 75%;
      border-right: 1px solid $lineColor;
    }
    .right{
      width: 25%;
      padding-left: 45px;
    }
  }
}
</style>